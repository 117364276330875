import "./inline.scss";
import "./main.scss";
// import "./grid.scss";
import "lazysizes";
import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper/core";
SwiperCore.use([Navigation, Pagination, EffectFade]);
import Player from "@vimeo/player";

var w = window,
  d = document,
  mvgM = 0,
  mvgE = d.querySelector("#bg div"),
  mvgW;
// 390/116

// function resize() {
//   let mvgH = w.innerWidth > 850 ? w.innerHeight - 280 : w.innerWidth;
//   mvgW = mvgH * (390 / 116);
// }

var allPlayer = [];

d.querySelectorAll("iframe").forEach(function (iframe) {
  var player = new Player(iframe);
  allPlayer.push(player);
  iframe.player = player;
});

// resize();

const swiper = new SwiperCore(".swiper-container", {
  effect: "fade",
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

swiper.on("activeIndexChange", function (e) {
  var iframe = d.querySelector(
    '.swiper-slide:not(.swiper-slide-duplicate)[data-swiper-slide-index="' +
      e.realIndex +
      '"] iframe'
  );
  pausePlayer();
  if (iframe) {
    iframe.player.play();
  }
});

function pausePlayer() {
  if (allPlayer.length) {
    allPlayer.forEach(function (p) {
      p.pause();
    });
  }
}

setTimeout(function () {
  swiper.update();
}, 900);

d.addEventListener("lazyloaded", function () {
  swiper.update();
  setTimeout(function () {
    swiper.update();
  }, 300);
});

function animateBg() {
  if (mvgM < mvgW) {
    mvgM = mvgM + 1;
  } else {
    mvgM = 0;
  }
  let style = "";
  ["-webkit-", "-moz-", ""].forEach(function (a) {
    style += a + "transform:translate3d(-" + mvgM + "px,0,0);";
  });
  mvgE.setAttribute("style", style);
  w.requestAnimationFrame(animateBg);
}

//animateBg();

// w.addEventListener("resize", resize);

d.addEventListener("click", function (e) {
  var target = e.target,
    i;
  if (
    target.id &&
    (target.id == "work" || target.id == "info" || target.id == "gallery")
  ) {
    page("/");
  } else {
    for (i = 0; i < 5; i++) {
      if (target && target.href) {
        if (!/mailto:|https?:/.test(target.getAttribute("href"))) {
          e.preventDefault();
          page(target.getAttribute("href"));
        }
        break;
      } else {
        target.parentElement && (target = target.parentElement);
      }
    }
  }
});

page(w.location.pathname);

w.addEventListener("popstate", (e) => {
  page(w.location.pathname);
});

function page(href) {
  href = href.replace(/^\/|\/$/, "").split("/");
  console.log("href", href);
  pausePlayer();
  if (href[0]) {
    d.body.dataset.p = href[0];
  } else {
    d.body.removeAttribute("data-p");
  }
  if (href[1]) {
    d.body.dataset.s = href[1];
    swiper.update();
    setTimeout(function () {
      swiper.update();
    }, 300);
    console.log(
      d
        .querySelector('#gallery [data-s="' + href[1] + '"]')
        .getAttribute("data-swiper-slide-index") + 1
    );
    swiper.slideTo(
      parseInt(
        d
          .querySelector('#gallery [data-s="' + href[1] + '"]')
          .getAttribute("data-swiper-slide-index")
      ) + 1,
      0
    );
  } else {
    d.body.removeAttribute("data-s");
  }
}
